import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="board"
export default class extends Controller {
  static targets = ["frame"];
  static values = {
    refreshInterval: Number,
    urls: Array,
    index: Number,
    intialized: false,
    currentScreen: Number,
  };

  initialize() {
    // two minutes
    this.refreshIntervalValue = 1000 * 60 * 2;

    // five seconds
    //this.refreshIntervalValue = 1000 * 5;
    this.urlsValue = ["/", "/?month=next"];
    this.initializedValue = true;
    this.currentScreen = 0;
  }

  connect() {
    this.next();
    this.startRefreshing();
  }

  next() {
    fetch("/board/next_screen?current=" + this.currentScreenValue)
      .then((response) => response.text())
      .then((data) => {
        this.currentScreenValue = data;
        this.loadCurrentPage();
      });
  }

  loadCurrentPage() {
    const url = `/screens/${this.currentScreenValue}`;
    console.log("Loading " + url);
    this.frameTarget.src = url;
  }

  startRefreshing() {
    setInterval(() => {
      this.next();
    }, this.refreshIntervalValue);
  }
}
